import React from 'react';
import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';
import {Link} from "react-router-dom";

const Terms = () => {
    return (
        <>
            <Header />
            <section className="features-two">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 offset-lg-2">
                            <h1>Terms of Use</h1>
                            <p>Last updated: February 15, 2024</p>
                            <br/>
                            <p>Welcome to PlantCare! These Terms of Use ("Terms") govern your access to and use of the PlantCare website located at <Link to='https://plantcare.app'>https://plantcare.app</Link> (the "Website"), including any content, functionality, and services offered on or through the Website.</p>
                            <p>By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Website.</p>
                            <br/>
                            <h3>1. Access to the Website</h3>
                            <p><b>1.1 Eligibility:</b> By accessing or using the Website, you represent and warrant that you are at least 18 years of age or have obtained the necessary consent from a parent or legal guardian to use the Website.</p>
                            <p><b>1.2 Account Registration:</b> Some features of the Website may require you to register for an account. When registering, you agree to provide accurate and complete information and to keep your account credentials confidential. You are responsible for all activities that occur under your account.</p>
                            <p><b>1.3 Availability:</b> We strive to ensure the availability of the Website, but we do not guarantee that the Website will be available at all times. We may suspend or terminate access to the Website for maintenance, upgrades, or any other reason, without prior notice.</p>
                            <br/>
                            <h3>2. Use of the Website</h3>
                            <p><b>2.1 Permitted Use:</b> You may use the Website for your personal, non-commercial purposes in accordance with these Terms. You agree not to use the Website for any unlawful purpose or in any way that violates these Terms.</p>
                            <p><b>2.2 Prohibited Activities:</b> You agree not to:</p>
                            <p>
                                Use the Website in any manner that could disable, overburden, damage, or impair the Website or interfere with any other party's use of the Website.
                                Attempt to gain unauthorized access to any portion of the Website, other accounts, or any systems or networks connected to the Website.
                                Use any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.
                                Reverse engineer, decompile, or disassemble any portion of the Website.
                                Use the Website to transmit any viruses, worms, defects, Trojan horses, or other items of a destructive nature.
                            </p>
                            <br/>
                            <h3>3. Intellectual Property Rights</h3>
                            <p><b>3.1 Ownership:</b> The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by PlantCare or its licensors and are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
                            <p><b>3.2 License:</b> Subject to these Terms, PlantCare grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Website for your personal, non-commercial use.</p>
                            <br/>
                            <h3>4. Privacy</h3>
                            <p><b>4.1 Privacy Policy:</b> Your use of the Website is subject to our Privacy Policy, which is incorporated by reference into these Terms. Please review the Privacy Policy carefully for information about how we collect, use, and disclose your personal information.</p>
                            <br/>
                            <h3>5. Contact Us</h3>
                            <p>If you have any questions about these Terms or the Website, please contact us at <Link to='https://plantcare.app/contact'>https://plantcare.app/contact</Link>.</p>
                            <br/>
                            <h3>6. Changes to the Terms</h3>
                            <p>We may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when we post them, and your continued use of the Website after the posting of revised Terms means that you accept and agree to the changes. It is your responsibility to check these Terms periodically for changes.</p>
                            <br/>
                            <h3>7. Governing Law and Jurisdiction</h3>
                            <p>These Terms and any dispute or claim arising out of or relating to these Terms or their subject matter will be governed by and construed in accordance with the laws of New York, without giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or proceeding arising out of or relating to these Terms or the Website shall be instituted exclusively in the federal courts of the United States or the courts of New York, and you waive any objection to the venue or jurisdiction of such courts.</p>
                            <p className='mt-5'>By using the Website, you consent to the personal jurisdiction and venue of such courts and waive any objection based on inconvenient forum.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Terms;