import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Navigation } from "swiper";

export default class Hero extends React.Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                <section className="main-slider main-slider-three">
                    <Swiper
                        loop={true}
                        slidesPerView={1}
                        effect="fade"
                        autoplay={{ delay: 5000 }}
                        modules={[EffectFade, Pagination, Navigation]}
                        pagination={{
                            el: "#main-slider-pagination",
                            type: "bullets",
                            clickable: true
                        }}
                        navigation={{
                            nextEl: "#main-slider__swiper-button-next",
                            prevEl: "#main-slider__swiper-button-prev"
                        }}>
                        <div className="swiper-wrapper">
                            {/* Start Main Slider Three */}
                            <SwiperSlide className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/home/banner.png)' }}></div>
                                <div className="shape1"><img src={publicUrl + "assets/images/shapes/slider-v3-shape1.png"} alt="#" /></div>
                                <div className="container">
                                    <div className="main-slider-three__content">
                                        <div className="title">
                                            <h2>
                                                Extend your <br />
                                                smart home to <br />
                                                your plants <br />
                                                and home garden
                                            </h2>
                                        </div>

                                        <div className="text">
                                            <p>
                                                From avid gardening enthusiasts managing their container  <br />
                                                garden with a paper notebook to individual plant-keepers, <br />
                                                PlantCare has solutions to help you plan and maintain the <br />
                                                healthiest possible flora from home or on the road.
                                            </p>
                                        </div>

                                        <div className="btn-box">
                                            <Link to='moisture-one' className="thm-btn">
                                                <span className="txt">Moisture One</span>&nbsp;
                                                <i className="fa fa-angle-double-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End Main Slider Three */}
                        </div>

                        {/* If we need navigation buttons */}
                        {/* <div className="main-slider__nav">
                            <div className="swiper-button-prev" id="main-slider__swiper-button-next">
                                <i className="icon-left-arrow"></i>
                            </div>
                            <div className="swiper-button-next" id="main-slider__swiper-button-prev">
                                <i className="icon-right-arrow"></i>
                            </div>
                        </div> */}
                    </Swiper>
                </section>
            </>
        )
    }
}