import React from 'react';

export default class InvestorOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="features-two">
                    <div className="features-two__bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/features-v2-bg.png)'}}></div>
                    <div className="auto-container">
                        <div className="row">
                            <h2 className="col-xl-8 col-lg-8 offset-lg-2 pb-2">Investors</h2>
                            
                            <div className="col-xl-8 col-lg-8 offset-lg-2">
                                <p>If you would like to contact us about any investment inquiries please reach out to luis @ plantcare.app</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}