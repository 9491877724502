import React from 'react';

export default class FaqOne extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($(".odometer").length) {
            var odo = $(".odometer");
            odo.each(function () {
                $(this).appear(function () {
                    var countNumber = $(this).attr("data-count");
                    $(this).html(countNumber);
                });
            });
        }

        if ($('.accordion-box').length) {
            $(".accordion-box").on('click', '.acc-btn', function () {

                var outerBox = $(this).parents('.accordion-box');
                var target = $(this).parents('.accordion');

                if ($(this).hasClass('active') !== true) {
                    $(outerBox).find('.accordion .acc-btn').removeClass('active');
                }

                if ($(this).next('.acc-content').is(':visible')) {
                    return false;
                } else {
                    $(this).addClass('active');
                    $(outerBox).children('.accordion').removeClass('active-block');
                    $(outerBox).find('.accordion').children('.acc-content').slideUp(300);
                    target.addClass('active-block');
                    $(this).next('.acc-content').slideDown(300);
                }
            });
        }

    }
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                <section className="faq-two">
                    <div className="shape1 float-bob-y">
                        <img src={publicUrl + "assets/images/shapes/faq-v2-shape1.png"} alt="#" />
                    </div>
                    <div className="container">
                        <div className="row">
                            {/* Start Faq Two Accordion */}
                            <div className="col-xl-5">
                                <div className="faq-two__accordion">
                                    <div className="sec-title-three">
                                        <div className="sec-title-three__tagline">
                                            <h6>faq</h6>
                                        </div>
                                        <h2 className="sec-title-three__title">Questions?</h2>
                                    </div>

                                    <ul className="accordion-box">
                                        <li className="accordion block active-block">
                                            <div className="acc-btn active">
                                                <div className="icon-outer">
                                                    <i className="icon-plus-1"></i>
                                                </div>
                                                <h3>
                                                    1. When we plan on exiting beta?
                                                </h3>
                                            </div>
                                            <div className="acc-content current">
                                                <p>We plan on exiting beta status after a full season of beta testing.</p>
                                            </div>
                                        </li>

                                        <li className="accordion block">
                                            <div className="acc-btn">
                                                <div className="icon-outer">
                                                    <i className="icon-plus-1"></i>
                                                </div>
                                                <h3>
                                                    2. How can i get a sensor?
                                                </h3>
                                            </div>
                                            <div className="acc-content">
                                                <p>Our sensors are currently still in R&D to ensure the best experience.</p>
                                            </div>
                                        </li>

                                        <li className="accordion block">
                                            <div className="acc-btn">
                                                <div className="icon-outer">
                                                    <i className="icon-plus-1"></i>
                                                </div>
                                                <h3>
                                                    3. Do you have integrations?
                                                </h3>
                                            </div>
                                            <div className="acc-content">
                                                <p>Integrations will be coming soon in the form of MQTT and likely an API for Pro users.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Faq Two Accordion */}

                            {/* Start Faq Two Content */}
                            <div className="col-xl-7">
                                <div className="faq-two__content">
                                    <div className="faq-two__content-img">
                                        <img src={publicUrl + "assets/images/faq/faq-one.png"} alt="#" />
                                    </div>

                                    <div className="faq-two__content-bottom clearfix">
                                        <div className="experience-box text-center">
                                            <div className="icon-box">
                                                <span className="icon-gardener-1"></span>
                                            </div>

                                            <div className="text-box">
                                                <h3>
                                                    <span className="odometer" data-count="10">00</span>
                                                    <span className="plus">+</span>Years of <br /> experience
                                                </h3>
                                            </div>
                                        </div>

                                        <div className="faq-two__content-bottom-inner">
                                            <ul className="faq-two__content-list">
                                                <li>
                                                    <div className="icon-box">
                                                        <span className="icon-tick"></span>
                                                    </div>

                                                    <div className="text-box">
                                                        <p>Team of software <br />engineers</p>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="icon-box">
                                                        <span className="icon-tick"></span>
                                                    </div>

                                                    <div className="text-box">
                                                        <p>Smart Home Friendly</p>
                                                    </div>
                                                </li>
                                            </ul>

                                            <ul className="faq-two__content-list style2">
                                                <li>
                                                    <div className="icon-box">
                                                        <span className="icon-tick"></span>
                                                    </div>

                                                    <div className="text-box">
                                                        <p>Hardware with a <br />purpose</p>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="icon-box">
                                                        <span className="icon-tick"></span>
                                                    </div>

                                                    <div className="text-box">
                                                        <p>MQTT Experience</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Faq Two Content */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}