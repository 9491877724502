import React from 'react';
import Header from '../common/header/Header';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import InvestorOne from '../components/investors/InvestorOne';
import Footer from '../common/footer/Footer';

const Investors = () => {
    return (
        <>
            <Header />
            <Breadcrumb
                heading="Investors"
                currentPage="Investors"
                Img="about-banner.png"
            />
            <InvestorOne />
            <Footer />
        </>
    )
}

export default Investors;