import React from 'react';
import Header from '../common/header/Header';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FaqOne from '../components/faq/FaqOne';
import Footer from '../common/footer/Footer';

const Faq = () => {
    return (
        <>
            <Header />
            <Breadcrumb
                heading="Faq"
                currentPage="Faq"
                Img="faq-banner.png"
            />
            <FaqOne />
            <Footer />
        </>
    )
}

export default Faq;