import React from 'react';
import {Link} from "react-router-dom";
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import Logo from './Logo';


export default class Header extends React.Component {
    render(){
        return (
            <>
                <header className="main-header main-header-three">

                    <div className="main-header-three__inner">
                        <nav className="main-menu main-menu-one">
                            <div className="main-menu__wrapper clearfix">
                                <div className="container-fluid">
                                    <div className="main-menu__wrapper-inner">

                                        <div className="main-header-three__left">
                                            <div className="main-menu-box">
                                                <MobileMenu />
                                                <Nav />
                                            </div>
                                        </div>

                                        <div className="main-header-three__middle">
                                            <Logo />
                                        </div>

                                        <div className="main-header-three__right">
                                            <div className="right-box">
                                                <div className="btn-box">
                                                    <Link to='https://my.plantcare.app/login'>Login</Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                </header>
            </>
        )
    }
}