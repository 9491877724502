import React from 'react';

export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            formStatus: '' // Added formStatus to track the status of form submission
        };
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleSubmit = event => {
        event.preventDefault();

        const formData = {
            name: this.state.name,
            email: this.state.email,
            body: this.state.message
        };

        fetch('https://api.plantcare.app/api/v1/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // Parse response JSON
        })
        .then(data => {
            this.setState({
                formStatus: data.status // Update formStatus based on server response
            });

            // Clear form fields
            if (data.status === 'success') {
                this.setState({
                    name: '',
                    email: '',
                    message: ''
                });
            }
        })
        .catch(error => {
            console.error('There was an error sending the message:', error);
            this.setState({
                formStatus: 'error' // Update formStatus to indicate error
            });
        });
    };

    render() {
        let message = '';
        if (this.state.formStatus === 'success') {
            message = <div class="alert alert-success mt-2" role="alert">Message sent successfully!</div>;
        } else if (this.state.formStatus === 'error') {
            message = <div class="alert alert-danger mt-2" role="alert">There was an error sending the message. Please try again later.</div>;
        }

        return (
            <>
                <section className="contact-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="contact-one__form contact-one__form--contact">
                                    <div className="sec-title">
                                        <div className="sec-title__tagline">
                                            <h6>Contact PlantCare</h6>
                                            <span className="right"></span>
                                        </div>
                                        <h2 className="sec-title__title"></h2>
                                    </div>

                                    <form onSubmit={this.handleSubmit} className="default-form2 contact-form-validated">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="input-box">
                                                    <input type="text" name="name" value={this.state.name} onChange={this.handleInputChange} placeholder="Your Name" required />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="input-box">
                                                    <input type="email" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder="Email Address" required />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="input-box">
                                                    <textarea name="message" value={this.state.message} onChange={this.handleInputChange} placeholder="Write a Message"></textarea>
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="contact-one__form-btn">
                                                    <button className="thm-btn" type="submit" data-loading-text="Please wait...">
                                                        <span className="txt">Contact Us Now</span>
                                                        <i className="fa fa-angle-double-right"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    {message} {/* Display message based on formStatus */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
