import React from 'react';
import { Link } from 'react-router-dom';

export default class Product extends React.Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                <section className="services-one">
                    <div className="gradient-bg"></div>
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Our Products</h6>
                                <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title">Professional Products</h2>
                        </div>

                        <div className="row">
                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                <div className="services-one__single">
                                    <div className="shape1"><img src={publicUrl + "assets/images/shapes/services-v1-shape2.png"} alt="#" /></div>
                                    <div className="shape2"><img src={publicUrl + "assets/images/shapes/services-v1-shape3.png"} alt="#" /></div>
                                    <div className="shape3"><img src={publicUrl + "assets/images/shapes/services-v1-shape4.png"} alt="#" /></div>
                                    <div className="services-one__single-img">
                                        <div className="services-one__single-img-bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/shapes/services-v1-shape1.png)' }}></div>
                                        <div className="overlay-icon">
                                            <div className="icon-box">
                                                <span className="icon-gardening-1"></span>
                                            </div>
                                        </div>
                                        <img src={publicUrl + "assets/images/moisture-1/plant-stand-many.jpg"} alt="#" />
                                    </div>

                                    <div className="services-one__single-content text-center">
                                        <h2><Link to={process.env.PUBLIC_URL + `/moisture-one`}>Moisture-One </Link></h2>
                                        <p>
                                            Never over- or under-water a plant again with PlantCare's flagship product,
                                            the Moisture-One. A wi-fi based smart home* moisture sensor providing notifications
                                            (via the associated app) when moisture isn't at an optimal level, the Moisture-One
                                            features a small rugged design that can function indoors or outdoors for plant-keepers
                                            and gardeners of any skill level <br /><br />
                                            * via 3rd party integrations
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}