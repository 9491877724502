import React from 'react';

export default class AboutOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="features-two">
                    <div className="features-two__bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/features-v2-bg.png)'}}></div>
                    <div className="auto-container">
                        <div className="row">
                            <h2 className="col-xl-8 col-lg-8 offset-lg-2 pb-2">PlantCare</h2>
                            
                            <div className="col-xl-8 col-lg-8 offset-lg-2">
                                <p>At PlantCare, based in Buffalo, NY, we empower you to cultivate thriving plants both indoors and in your home garden by providing essential, real-time information accessible on your phone, tablet, or PC. Our platform integrates seamlessly with popular systems, and through the PlantCare app, you receive timely alerts when conditions exceed your custom-set thresholds, enabling you to take immediate action when your plants need it most.</p>
                                <br/>
                                <p>Our flagship product, the Moisture-One, monitors soil moisture levels and pairs effortlessly with the PlantCare app. Whether you have a single sensor or several spread across your home and garden, Moisture-One helps you maintain the perfect moisture balance for a variety of plants. From cacti to tomatoes, you can confidently avoid under or over-watering and provide your plants with the optimal conditions they need to thrive.</p>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <h3 className="col-xl-8 col-lg-8 offset-lg-2 pb-2">Luis Rodriguez</h3>
                            <h4 className="col-xl-8 col-lg-8 offset-lg-2 pb-2">Founder / CGO</h4>

                            <div className="col-xl-8 col-lg-8 offset-lg-2">
                                <p>As the Founder and Chief Gardening Officer of PlantCare, based in Buffalo, NY, Luis combines his passion for technology with his love for gardening to create innovative solutions that help people care for their plants. With a background in software engineering and years of hands-on experience, he’s dedicated to making smart gardening tools accessible and easy to use. PlantCare is his vision for the future of gardening, where technology empowers everyone to nurture their green spaces with confidence.</p>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <h3 className="col-xl-8 col-lg-8 offset-lg-2 pb-2">Andrew Elbaneh</h3>
                            <h4 className="col-xl-8 col-lg-8 offset-lg-2 pb-2">Co-Founder / Software Engineer</h4>

                            <div className="col-xl-8 col-lg-8 offset-lg-2">
                                <p>Co-Founder and Software Engineer at PlantCare, from Buffalo, NY, Andrew brings over a decade of experience in software development to the smart gardening space. Passionate about technology and innovation, Andrew has worked across various industries, developing robust solutions that empower teams and clients. His expertise in cloud platforms, modern web technologies, and agile methodologies drives PlantCare’s mission to seamlessly integrate smart technology into gardening. Andrew is committed to building tools that make plant care more intuitive and accessible for everyone.</p>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <h3 className="col-xl-8 col-lg-8 offset-lg-2 pb-2">Don Ko</h3>
                            <h4 className="col-xl-8 col-lg-8 offset-lg-2 pb-2">Co-Founder / CMO</h4>

                            <div className="col-xl-8 col-lg-8 offset-lg-2">
                                <p>
                                Co-founder and Chief Marketing Officer at PlantCare, boasts over 15 years of experience in product management and marketing for high-tech products. Hailing from Buffalo, NY, Don's passion for home automation began nearly a decade ago with connected lightbulbs and has since evolved into a fascination with smart home devices. This interest has seamlessly translated into his role at PlantCare, where he is thrilled to be part of extending smart technology to the garden. Don earned his MBA from the University of Rochester’s Simon School of Business in 2010, specializing in Competitive and Organizational Strategy and Marketing, and has previously worked on a range of products from storage appliances to SaaS solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}