import React from 'react';
import handleSubscribe from '../../common/newsletter/subscribe';
import { Link } from 'react-router-dom';

export default class Footer extends React.Component {
    handleSubmit = (event) => {
        //Prevent default
        event.preventDefault();

        //Grab email
        const email = document.getElementById('footerEmail').value;

        handleSubscribe(email, false,
            function (message) {
                console.log('Success:', message);

                //Hide any error messages
                document.querySelector('.footer-msg.bg-danger').classList.add('d-none');

                //Show success message
                const successMsg = document.querySelector('.footer-msg.bg-success');
                successMsg.innerHTML = message;
                successMsg.classList.remove('d-none');
            },
            function (message) {
                console.error('Error:', message);

                //Hide any success messages
                document.querySelector('.footer-msg.bg-success').classList.add('d-none');

                //Show error message
                const errorMsg = document.querySelector('.footer-msg.bg-danger');
                errorMsg.innerHTML = message;
                errorMsg.classList.remove('d-none');
            });
    };

    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                <footer className="footer-one">
                    <div className="footer-one__bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/shapes/footer-v1-shape3.png)' }}></div>
                    <div className="shape1 float-bob-y"><img src={publicUrl + "assets/images/shapes/footer-v1-shape1.png"} alt="#" /></div>
                    <div className="shape2 float-bob-y"><img src={publicUrl + "assets/images/shapes/footer-v1-shape2.png"} alt="#" /></div>
                    {/* Start Footer */}
                    <div className="footer">
                        <div className="container">
                            <div id="status-container" className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <div className="footer-msg p-2 m-2 bg-success border border-secondary rounded text-white d-none">.bg-success</div>
                                        <div className="footer-msg p-2 m-2 bg-danger border border-danger rounded text-white d-none">.bg-danger</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {/* Start Footer Widget Single */}
                                <div className="col-xl-5 col-lg-5  wow animated fadeInUp" data-wow-delay="0.1s">
                                    <div className="footer-widget__single">
                                        <div className="footer-widget__single-about">
                                            <form className="footer-widget__subscribe-box">
                                                <div className="title-box">
                                                    <h5>Subscribe for Latest Articles and Resources</h5>
                                                </div>
                                                <div className="footer-widget__subscribe-input-box">
                                                    <input id="footerEmail" type="email" placeholder="Email Address" name="email" />
                                                    <button type="submit" onClick={this.handleSubmit} className="footer-widget__subscribe-btn">
                                                        <span>Go</span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {/* End Footer Widget Single */}

                                {/* Start Footer One Right */}
                                <div className="col-xl-7 col-lg-7">
                                    <div className="footer-one__right">
                                        <div className="row">
                                            {/* Start Footer One Right Single */}
                                            <div className="col-xl-3 col-lg-3 wow animated fadeInUp" data-wow-delay="0.3s">
                                                <div className="footer-one__right-single mb50">
                                                    <div className="title">
                                                        <h2>Links</h2>
                                                    </div>
                                                    <div className="footer-one__right-single-links">
                                                        <ul className="footer-one__right-single-list">
                                                            <li><Link to={process.env.PUBLIC_URL + `/about`}>About Us</Link></li>
                                                            {/* <li><Link to='https://www.facebook.com/groups/3553077875004114' target="_blank">News</Link></li> */}
                                                            <li><Link to={process.env.PUBLIC_URL + `/contact`}>Contact Us</Link></li>
                                                            <li><Link to={process.env.PUBLIC_URL + `/investors`}>Investors</Link></li>
                                                            <li><Link to={process.env.PUBLIC_URL + `/faq`}>FAQ</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Footer One Right Single */}

                                            {/* Start Footer One Right Single */}
                                            <div className="col-xl-5 col-lg-5 wow animated fadeInUp" data-wow-delay="0.4s">
                                                <div className="footer-one__right-single">
                                                    <div className="title">
                                                        <h2>Contact</h2>
                                                    </div>
                                                    <div className="footer-one__right-single-contact">
                                                        <p><a href="mailto:help@plantcare.app">help@plantcare.app</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Footer One Right Single */}
                                        </div>
                                    </div>
                                </div>
                                {/* End Footer One Right */}
                            </div>
                        </div>
                    </div>


                    <div className="footer-one__bottom">
                        <div className="container">
                            <div className="bottom-inner">
                                <div className="copyright">
                                    <p>Copyright PlantCare © 2024 All Rights Reserved.</p>
                                </div>

                                <ul className="footer-one__bottom-menu">
                                    <li><Link to={process.env.PUBLIC_URL + `/terms`}>Terms & Condition</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/privacy`}>Privacy </Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </footer>
            </>
        )
    }
}